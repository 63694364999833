<template>
    <div class="staff-department" v-if="status === 'success'">
        <template v-if="positionsView.length > 0">
            <div class="staff-department__box box">
                <table class="staff-department__table staff-department__table_striped">
                    <colgroup>
                        <col class="staff-department__table-column staff-department__table-column_position">
                        <col class="staff-department__table-column staff-department__table-column_staff-num">
                        <col class="staff-department__table-column staff-department__table-column_closed-vacancies">
                        <col class="staff-department__table-column staff-department__table-column_opened-vacancies">
                        <col class="staff-department__table-column staff-department__table-column_rate">
                        <col class="staff-department__table-column staff-department__table-column_wage-sum">
                    </colgroup>
                    <thead>
                        <tr class="staff-department__table-row staff-department__table-row_head">
                            <th class="staff-department__table-cell staff-department__table-cell_head staff-department__table-cell_sortable"
                                scope="col"
                                @click="setSorting('position_short')"
                            >
                                <span class="staff-department__table-value">Должность</span>
                                <svg-icon class="staff-department__table-value staff-department__table-value_icon"
                                    v-if="sorting.field === 'position_short' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="staff-department__table-value staff-department__table-value_icon"
                                    v-if="sorting.field === 'position_short' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="staff-department__table-cell staff-department__table-cell_head staff-department__table-cell_sortable"
                                scope="col"
                                @click="setSorting('staff_num')"
                            >
                                <span class="staff-department__table-value">Число ставок</span>
                                <svg-icon class="staff-department__table-value staff-department__table-value_icon"
                                    v-if="sorting.field === 'staff_num' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="staff-department__table-value staff-department__table-value_icon"
                                    v-if="sorting.field === 'staff_num' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="staff-department__table-cell staff-department__table-cell_head staff-department__table-cell_sortable"
                                scope="col"
                                @click="setSorting('closed_vacancies')"
                            >
                                <span class="staff-department__table-value">Занято</span>
                                <svg-icon class="staff-department__table-value staff-department__table-value_icon"
                                    v-if="sorting.field === 'closed_vacancies' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="staff-department__table-value staff-department__table-value_icon"
                                    v-if="sorting.field === 'closed_vacancies' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="staff-department__table-cell staff-department__table-cell_head staff-department__table-cell_sortable"
                                scope="col"
                                @click="setSorting('opened_vacancies')"
                            >
                                <span class="staff-department__table-value">Вакансий</span>
                                <svg-icon class="staff-department__table-value staff-department__table-value_icon"
                                    v-if="sorting.field === 'opened_vacancies' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="staff-department__table-value staff-department__table-value_icon"
                                    v-if="sorting.field === 'opened_vacancies' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="staff-department__table-cell staff-department__table-cell_head staff-department__table-cell_sortable"
                                scope="col"
                                @click="setSorting('rate')"
                            >
                                <span class="staff-department__table-value">Ставка</span>
                                <svg-icon class="staff-department__table-value staff-department__table-value_icon"
                                    v-if="sorting.field === 'rate' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="staff-department__table-value staff-department__table-value_icon"
                                    v-if="sorting.field === 'rate' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="staff-department__table-cell staff-department__table-cell_head staff-department__table-cell_sortable"
                                scope="col"
                                @click="setSorting('wage_fund')"
                            >
                                <span class="staff-department__table-value">ФОТ</span>
                                <svg-icon class="staff-department__table-value staff-department__table-value_icon"
                                    v-if="sorting.field === 'wage_fund' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="staff-department__table-value staff-department__table-value_icon"
                                    v-if="sorting.field === 'wage_fund' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="staff-department__table-row staff-department__table-row_body staff-department__table-row_pinned">
                            <td class="staff-department__table-cell staff-department__table-cell_body staff-department__table-cell_title">
                                Все должности
                            </td>
                            <td class="staff-department__table-cell staff-department__table-cell_body">
                                {{totalView.number | intFormat}}
                            </td>
                            <td class="staff-department__table-cell staff-department__table-cell_body">
                                {{totalView.number_closed | intFormat}}
                            </td>
                            <td class="staff-department__table-cell staff-department__table-cell_body">
                                {{totalView.number_open | intFormat}}
                            </td>
                            <td class="staff-department__table-cell staff-department__table-cell_body">&nbsp;</td>
                            <td class="staff-department__table-cell staff-department__table-cell_body">
                                {{totalView.sum_fot | currencyFloatFormat}}
                            </td>
                        </tr>
                        <tr class="staff-department__table-row staff-department__table-row_body"
                            v-for="position in positionsView" :key="position.id"
                        >
                            <td class="staff-department__table-cell staff-department__table-cell_body">
                                {{position.position_short}}
                            </td>
                            <td class="staff-department__table-cell staff-department__table-cell_body">
                                {{position.staff_num | intFormat}}
                            </td>
                            <td class="staff-department__table-cell staff-department__table-cell_body">
                                {{position.closed_vacancies | intFormat}}
                            </td>
                            <td class="staff-department__table-cell staff-department__table-cell_body">
                                {{position.opened_vacancies | intFormat}}
                            </td>
                            <td class="staff-department__table-cell staff-department__table-cell_body">
                                {{position.rate | currencyFloatFormat}}
                            </td>
                            <td class="staff-department__table-cell staff-department__table-cell_body">
                                {{position.wage_fund | currencyFloatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <template v-else>
            <p>Нет данных</p>
        </template>
    </div>
</template>

<script>
    import { mapState, mapMutations } from "vuex";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import { isTradePoint } from "@/helpers/departments";
    import { isAdministratorPosition } from "@/helpers/positions";

    export default {
        name: "StaffDepartment",
        props: {
            id: {
                type: String,
                required: true
            },
            filters: {
                type: Array,
                required: true
            },
            filterId: {
                type: String,
                required: true
            },
            isFiltersVisible: {
                type: Boolean,
                required: true
            },
            status: {
                type: String,
                required: true
            },
            isReportTotalLinkVisible: {
                type: Boolean,
                required: true
            },
            departments: {
                required: true
            },
            userStaffUnits: {
                required: true
            },
            calcPositionsTotal: {
                type: Function,
                required: true
            }
        },
        computed: {
            ...mapState({
                title: state => state.page.title,
                sorting: state => state.staff.sortings.department
            }),
            department() {
                return this.departments?.find(({ id }) => id === this.id);
            },
            positionsView() {
                return Object.values(this.department?.ranking)?.filter(position => {
                    switch (this.filterId) {
                        case "administrator":
                            return this.isAdministratorPosition(position.position_short);
                        case "ordinary":
                            return !this.isAdministratorPosition(position.position_short);
                        default:
                            return position;
                    }
                })?.sort((position1, position2) => {
                    let a = position1[this.sorting.field];
                    let b = position2[this.sorting.field];

                    switch (this.sorting.field) {
                        case "position_short":
                            return compareStrings(a, b, this.sorting.direction);
                        default:
                            return compareNumbers(a, b, this.sorting.direction);
                    }
                });
            },
            totalView() {
                return this.calcPositionsTotal(this.positionsView);
            }
        },
        methods: {
            ...mapMutations([
                "setTitle",
                "setStaffSortingDirection",
                "setStaffSortingField",
                "toggleStaffFilters",
                "toggleReportTotalLink"
            ]),
            setSorting(field) {
                if (this.sorting.field === field) {
                    this.setStaffSortingDirection({
                        table: "department",
                        direction: this.sorting.direction === "ASC" ? "DESC" : "ASC"
                    });
                } else {
                    this.setStaffSortingField({
                        table: "department",
                        field
                    });
                }
            },
            isTradePoint,
            isAdministratorPosition
        },
        created() {
            if (!this.userStaffUnits?.ids?.includes(this.id)) {
                this.$router.push({
                    name: "Error401",
                    params: {
                        requestedResource: `Подразделение ${this.id}`
                    }
                });
            } else {
                if (this.isFiltersVisible && !this.isTradePoint(this.id)) {
                    this.toggleStaffFilters(false);
                }

                let restaurantTitle = this.userStaffUnits?.byId?.[this.id]?.title;

                if (restaurantTitle && !this.title.includes(restaurantTitle)) {
                    this.setTitle(`${this.title} "${restaurantTitle}"`);
                }

                if (this.userStaffUnits?.ids?.length > 1 && !this.isReportTotalLinkVisible) {
                    this.toggleReportTotalLink(true);
                }
            }
        }
    }
</script>

<style lang="scss">
    .staff-department__box {
        padding: 8px 16px 16px;
        overflow-x: auto;

        & + & {
            margin-top: 30px;
        }
    }
    .staff-department__table {
        width: 100%;

        &_text-center {
            text-align: center;
        }
    }
    .staff-department__table-caption {
        padding: 8px 8px 0;
        font-size: 24px;
        font-weight: bold;
    }
    .staff-department__table-column {
        &_total {
            width: 25%
        }
        &_position {
            width: 31%;
        }
        &_staff-num {
            width: 13%;
        }
        &_closed-vacancies {
            width: 13%;
        }
        &_opened-vacancies {
            width: 13%;
        }
        &_rate {
            width: 15%;
        }
        &_wage-sum {
            width: 15%;
        }
    }
    .staff-department__table-row {
        &_body {
            .staff-department__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .staff-department__table-cell {
        font-size: 20px;
        padding: 16px 8px;

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }
    }
    .staff-department__table-value {
        vertical-align: middle;

        & + & {
            margin-left: 5px;
        }

        &_icon {
            width: 24px;
            height: 24px;
        }

        &_positive {
            color: green;
        }

        &_negative {
            color: red;
        }
    }
</style>
